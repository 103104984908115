(() => {
  mapboxgl.accessToken =
    'pk.eyJ1Ijoia2F2YXRhbW9sb2tvIiwiYSI6ImNrMTZobzMzdjA4dnkzYm1yOWlldWdqa3AifQ.DUftgvW-rhKRtvQ6fHapdQ';
  const mapContainer = document.querySelector('.map-container');

  // colors can be
  // -------------
  // black
  // yellow
  // blue
  // purple
  // rose
  // -------------

  const coordsObj = [
    {
      coord: [30.38185, 50.419774],
      title: 'бул. Кольцова, 13',
      image: 'images/4b-place.png',
      color: 'purple'
    },
    {
      coord: [30.379513, 50.422253],
      title: 'бул. Кольцова, 14Л',
      image: 'images/4b-place.png',
      color: 'yellow'
    },
    {
      coord: [30.494695, 50.39408],
      title: 'вул. Васильківська, 23/16',
      image: 'images/4b-place.png',
      color: 'purple'
    },
    {
      coord: [30.445861, 50.469848],
      title: 'вул. Дорогожицька, 13',
      image: 'images/4b-place.png',
      color: 'yellow'
    },
    {
      coord: [30.395405, 50.415227],
      title: 'вул. Жолудєва, 4',
      image: 'images/4b-place.png',
      color: 'purple'
    },
    {
      coord: [30.641542, 50.395219],
      title: 'вул. Руденко, 6А',
      image: 'images/4b-place.png',
      color: 'blue'
    },
    {
      coord: [30.626521, 50.409053],
      title: 'просп. Петра Григоренка, 22/20',
      image: 'images/4b-place.png',
      color: 'blue'
    },
    {
      coord: [30.508729, 50.396798],
      title: 'вул. Васильківська, 1',
      image: 'images/4b-place.png',
      color: 'blue'
    },
    {
      coord: [30.453559, 50.426521],
      title: 'вул. Донецька, 14/30',
      image: 'images/4b-place.png',
      color: 'purple'
    },
    {
      coord: [30.444327, 50.456615],
      title: 'просп. Перемоги, 50',
      image: 'images/4b-place.png',
      color: 'rose'
    },
    {
      coord: [30.517104, 50.427543],
      title: 'вул. Велика Васильківська, 73',
      image: 'images/4b-place.png',
      color: 'purple'
    }
  ];

  let zoomNumber = null;

  const resizeblZoom = () => {
    if (window.outerWidth <= 767) {
      zoomNumber = 9;
    } else {
      zoomNumber = 11;
    }

    if (mapContainer) {
      initMap(zoomNumber);
    }
  };

  window.onload = () => {
    resizeblZoom();
  };

  // window.addEventListener("resize", function() {
  //   resizeblZoom();
  // });

  const initMap = zoomVal => {
    const map = new mapboxgl.Map({
      container: mapContainer,
      style: 'mapbox://styles/mapbox/light-v9?optimize=true',
      center: [30.5083542, 50.4362128],
      zoom: zoomVal
    });

    coordsObj.forEach(el => {
      const div = document.createElement('div');
      const content = document.createElement('div');
      const image = document.createElement('img');
      const info = document.createElement('div');
      const infoText = document.createElement('p');

      div.className = `map-pin map-pin--${el.color}`;
      content.className = 'map-pin__content';
      image.className = 'map-pin__content-image';
      image.src = `${el.image}`;
      info.className = 'map-pin__info';
      infoText.className = 'map-pin__info-text';
      infoText.innerHTML = el.title;

      content.appendChild(image);
      div.appendChild(content);
      info.appendChild(infoText);
      div.appendChild(info);

      new mapboxgl.Marker(div).setLngLat(el.coord).addTo(map);
    });
  };
})();
