(() => {
  const carouselWrap = document.querySelector(".carousel-wrapper");
  const cupAuthors = document.querySelectorAll(".cup-author");
  const nextBtn = document.querySelector(".carousel-control-btn--next");
  const prevBtn = document.querySelector(".carousel-control-btn--prev");
  let cups = document.querySelectorAll(".cup-block");

  let state = {
    open: false,
    mobile: false
  };

  let pageSize,
    itemsCount = 0;

  const carouselProperties = {
    mouseDrag: false,
    touchDrag: false,
    dots: false,
    responsive: {
      0: {
        items: 1
      },
      576: {
        items: 2
      },
      767: {
        items: 3
      },
      992: {
        items: 4
      },
      1200: {
        items: 5
      },
      1440: {
        items: 6
      }
    }
  };

  const owl = $(carouselWrap);

  const checkArrow = index => {
    if (index + pageSize === itemsCount) {
      nextBtn.style.display = "none";
    } else {
      nextBtn.style.display = "block";
    }

    if (index === 0) {
      prevBtn.style.display = "none";
    } else {
      prevBtn.style.display = "block";
    }
  };

  owl.on("refreshed.owl.carousel", evt => {
    const windowW = window.innerWidth;
    cups = document.querySelectorAll(".cup-block");

    if (windowW < 576) {
      state.mobile = true;
    } else {
      state.mobile = false;
    }

    pageSize = evt.page.size;
    itemsCount = evt.item.count;

    cups.forEach(el => {
      const btn = el.querySelectorAll("[data-click-handle]");
      // const btn = el.querySelector(".cup-picture");
      const elData = el.getAttribute("data-cup");

      btn.forEach(handler => {
        handler.addEventListener("click", evt => {
          evt.preventDefault();

          if (elData) {
            const items = [].slice.call(cupAuthors);
            const author = items.find(elem => {
              return elem.getAttribute("data-cup-author") === elData;
            });

            toggleAuthor(author, el, cups);
          }
        });
      });
    });

    checkArrow(evt.item.index);
  });

  owl.owlCarousel(carouselProperties);

  owl.on("translate.owl.carousel", event => {
    checkArrow(event.item.index);
  });

  owl.on("translated.owl.carousel", event => {
    const items = event.relatedTarget.items();
    let active = items.find(el => {
      return el[0].classList.contains("active");
    });
    if (active) {
      active = active[0].querySelector(".cup-block");
    }

    if (state.open && state.mobile) {
      authorChange(active);
    }

    checkArrow(event.item.index);
  });

  $(nextBtn).click(function() {
    owl.trigger("next.owl.carousel");
  });

  $(prevBtn).click(function() {
    owl.trigger("prev.owl.carousel");
  });

  const toggleAuthor = (author, el, cups) => {
    if (state.open === false) {
      state.open = true;
    }

    cups.forEach(elem => {
      elem.classList.remove("cup-block--more");
    });
    cupAuthors.forEach(elem => {
      elem.setAttribute("hidden", "hidden");
    });

    author.removeAttribute("hidden");
    author.scrollIntoView({ behavior: "smooth" });
    el.classList.add("cup-block--more");
  };

  const authorChange = el => {
    const elData = el.getAttribute("data-cup");

    if (elData) {
      const items = [].slice.call(cupAuthors);
      const author = items.find(elem => {
        return elem.getAttribute("data-cup-author") === elData;
      });

      toggleAuthor(author, el, cups);
    }
  };
})();
